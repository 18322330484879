import { isMaskable, isSensitiveKey, OBSCURED_LITERAL } from '@bp/shared/models/core';
import { toPlainObject } from '@bp/shared/utilities/core';

import { MetadataEntity } from '../metadata-entity';

// eslint-disable-next-line unicorn/no-useless-undefined
export const omitSecretValuesRecursively = replaceSecretValuesRecursivelyWith(undefined);

export const obscureSecretValuesRecursively = replaceSecretValuesRecursivelyWith(OBSCURED_LITERAL);

function replaceSecretValuesRecursivelyWith<T extends object>(
	replacement: string | undefined,
): (object: T) => T {
	return object => toPlainObject(object, function(this, key, value) {
		const isSecretProperty = this instanceof MetadataEntity && this.classMetadata.get(<any>key)?.isSecret;

		if (isSecretProperty)
			return replacement;

		const isLogObscuredProperty = this instanceof MetadataEntity && this.classMetadata.get(<any>key)?.isLogObscured;

		if (isLogObscuredProperty) {
			const originalValue = this[<keyof MetadataEntity> key];

			return isMaskable(originalValue) ? originalValue.getMaskedValue() : replacement;
		}

		return isSensitiveKey(key) ? replacement : value;
	});
}
