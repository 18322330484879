import { camelCase } from 'lodash-es';

export class Sensitive {

	static readonly jwt = 'jwt';

	static readonly refreshToken = 'refreshToken';

	static readonly accessToken = 'accessToken';

	static readonly password = 'password';

	static readonly confirmPassword = 'confirmPassword';

	static readonly currentPassword = 'currentPassword';

	static readonly newPassword = 'newPassword';

	static readonly confirmNewPassword = 'confirmNewPassword';

	static readonly primaryAccountNumber = 'primaryAccountNumber';

	static readonly creditCardNumber = 'creditCardNumber';

	static readonly paymentCardNumber = 'paymentCardNumber';

	static readonly acctNumber = 'acctNumber';

	static readonly cvv = 'cvv';

	static readonly paymentCardCvv = 'paymentCardCvv';

	static readonly email = 'email';

}

const sensitiveKeys = new Set(Object.keys(Sensitive));

export function isSensitiveKey(key: string): boolean {
	return sensitiveKeys.has(camelCase(key));
}

export const TELEMETRY_HIDDEN_MARK = '(Secret)';

export const OBSCURED_LITERAL = '[FILTERED]';
